import { useRef } from "react";
import { Button, Container, HiddenInput } from "./styles"
import { IUploadButtonProps } from "./types"

const UploadButton: React.FC<IUploadButtonProps> = ({ children, marginTop, hidden, $backgroundColor, onFileUpload }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            onFileUpload(file);
        }
    };

    const handleClick = () => {
        if (fileInputRef.current) {
        fileInputRef.current.click();
        }
    };

    return (
        <Container marginTop={marginTop}>
            <HiddenInput type="file" ref={fileInputRef} onChange={handleFileChange} />
            <Button hidden={hidden} $backgroundColor={$backgroundColor} onClick={handleClick}>
                {children}
            </Button>
        </Container>
    );
}

export default UploadButton