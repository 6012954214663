import styled from 'styled-components'
import { ISquadColorProps } from './types'

export const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

export const SquadColor = styled.div<ISquadColorProps>`
    width: 20px;
    height: 20px;
    margin: 10px;
    background-color: ${props => props.color};
`

export const Legenda = styled.h2`
    color: ${props => props.theme.colors.white};
    font-size: 16px;
    font-weight: bold;
`