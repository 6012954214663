import styled from 'styled-components'
import { ITitleStyleProps } from './types'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
`

export const TitleContent = styled.h1<ITitleStyleProps>`
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.size}px;
    border-bottom: 10px solid ${props => props.color};
`