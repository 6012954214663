import { AxiosResponse } from 'axios'
import Api from '../../config/Api'
import { FormGoogle, GetResponse } from '../../models'
import { CreateObject } from '@testing-library/react'

export const listar = async (): Promise<AxiosResponse<GetResponse<FormGoogle[]>, any>> => {
    return await Api.get<GetResponse<FormGoogle[]>>(`FormGoogle?Offset=0&Limit=1&SortDesc=false`)
}

export const criar = async (model: FormGoogle): Promise<AxiosResponse<CreateObject, any>> => {
    return await Api.post<CreateObject>(`FormGoogle`, model)
}

export const atualizar = async (model: FormGoogle): Promise<AxiosResponse<void, any>> => {
    return await Api.put<void>(`FormGoogle`, model)
}