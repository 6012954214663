import { useTheme } from "styled-components";
import Button from "../../components/Button";
import GroupButtons from "../../components/GroupButtons";
import Input from "../../components/Input";
import Loader from "../../components/Loader";
import Title from "../../components/Title";
import { Container, Form, Logo } from "./styles";
import { IResetPasswordProps } from "./types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useAuthContext } from "../../hooks/auth";

const ResetPassword: React.FC<IResetPasswordProps> = () => {
    const [searchParams] = useSearchParams();
    const [email] = useState<string>(searchParams.get('email')!)
    const [token] = useState<string>(searchParams.get('token')!)
    const [senha, setSenha] = useState<string>('')
    const [confirmarSenha, setConfirmarSenha] = useState<string>('')

    const theme = useTheme()
    const navigate = useNavigate()
    const { loading, redirect, resetarSenha } = useAuthContext()

    const handleResetPassword = async (e: any): Promise<void> => {
        e.preventDefault()
        await resetarSenha(email!, senha, confirmarSenha, token!)
        setSenha('')
        setConfirmarSenha('')
    }

    if(redirect)
        navigate('/')
    
    return(
        <>
            {
                loading
                    ? <Loader />
                    : <Container>
                        <Logo>CheckMyList</Logo>
                        <Form onSubmit={(e: any) => handleResetPassword(e)}>
                            <Title color={theme!.colors.warning} size={30}>Redefinir Senha</Title>
                            <Input label="Senha *" placeholder="Senha *" type="password" required value={senha} onChange={(e) => setSenha(e.target.value)} />
                            <Input label="Confirmar Senha *" placeholder="Confirmar Senha *" type="password" required value={confirmarSenha} onChange={(e) => setConfirmarSenha(e.target.value)} />
                            <GroupButtons>
                                <Button $backgroundColor={theme!.colors.success} type="submit">Salvar</Button>
                            </GroupButtons>
                        </Form>
                    </Container>
            }
        </>
    )
}

export default ResetPassword