import { useTheme } from "styled-components";
import Title from "../Title";
import { ModalCloseButton, ModalContainer, ModalContent, ModalHeader, ModalOverlay } from "./styles"
import { IModalProps } from "./types"

const Modal: React.FC<IModalProps> = ({ isOpen, onClose, title, children }) => {
    const theme = useTheme()

    if (!isOpen) return null;

    return(
        <ModalOverlay>
            <ModalContainer>
                <ModalHeader>
                    <Title 
                        color={theme!.colors.warning}
                        size={30}
                    >
                        {title || 'Modal'}
                    </Title>
                    <ModalCloseButton onClick={onClose}>&times;</ModalCloseButton>
                </ModalHeader>
                <ModalContent>
                    {children}
                </ModalContent>
            </ModalContainer>
        </ModalOverlay>
    )
}

export default Modal