export enum EStatus {
    'Ativo' = 1,
    'Inativo' = 2
}

export const EStatusToString = (status: EStatus): string => {
    switch(status) {
        case EStatus.Ativo:
            return 'Ativo';
        default:
            return 'Inativo'
    }
}