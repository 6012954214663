import { useTheme } from "styled-components"
import Button from "../../components/Button"
import Input from "../../components/Input"
import Title from "../../components/Title"
import { Container, EsqueceuSenhaLink, Form, Logo } from "./styles"
import { ILoginProps } from "./types"
import GroupButtons from "../../components/GroupButtons"
import React, { useState } from "react"
import { useAuthContext } from "../../hooks/auth"
import Loader from "../../components/Loader"
import { useNavigate } from "react-router-dom"
import { confirmAlert } from "react-confirm-alert"
import { emailValido } from "../../utils/emailValido"

const Login: React.FC<ILoginProps> = () => {
    const [email, setEmail] = useState<string>('')
    const [senha, setSenha] = useState<string>('')

    const theme = useTheme()
    const navigate = useNavigate()
    const { login, loading, enviarEmail } = useAuthContext()

    const handleLogin = async (e: any) => {
        e.preventDefault()
        await login(email, senha)
    }

    const handleEsqueceuSenha = () => {
        if(emailValido(email)) {
            dadosPreenchidosAlert()
        } else {
            preencherDadosAlert()
        }
    }

    const dadosPreenchidosAlert = () => {
        confirmAlert({
            title: 'Enviar Email',
            message: `Deseja enviar um email de alteração de senha para ${email}?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => await enviarEmail(email)
                },
                {
                    label: 'Não',
                    onClick: () => {}
                }
            ]
        })
    }

    const preencherDadosAlert = () => {
        confirmAlert({
            title: 'Informar Email válido',
            message: 'Informe o email que será enviado o link para alteração de senha',
            buttons: [{ label: 'Ok' }]
        })
    }

    return(
        <>
            {loading 
                ? <Loader /> 
                : <Container>
                    <Logo>CheckMyList</Logo>
                    <Form onSubmit={(e: any) => handleLogin(e)}>
                        <Title color={theme!.colors.warning} size={30}>Identifique-se</Title>
                        <Input label="Email" placeholder="Email*" type='email' required onChange={(e) => setEmail(e.target.value)} />
                        <Input label="Senha" placeholder="Senha*" type='password' required onChange={(e) => setSenha(e.target.value)} />
                        <GroupButtons>
                            <Button $backgroundColor={theme!.colors.success} type="submit">Entrar</Button>
                            <Button $backgroundColor={theme!.colors.info} type="button" onClick={() => navigate('/registro')}>Registrar</Button>
                        </GroupButtons>
                        <EsqueceuSenhaLink type="button" onClick={handleEsqueceuSenha}>Esqueceu sua senha?</EsqueceuSenhaLink>
                    </Form>
                </Container> 
            }
        </>
    )
}

export default Login