import { useTheme } from "styled-components"
import { Container, Legenda, SquadColor } from "./styles"
import { IQrCodeLegendaProps } from "./types"
import { EStatusEnvio, EStatusEnvioToString } from "../../models"

const QrCodeLegenda: React.FC<IQrCodeLegendaProps> = () => {
    const theme = useTheme()

    return(
        <Container>
            <SquadColor color={theme!.colors.warning} />
            <Legenda>{EStatusEnvioToString(EStatusEnvio.NaoEnviado)}</Legenda>
            <SquadColor color={theme!.colors.info} />
            <Legenda>{EStatusEnvioToString(EStatusEnvio.Enviado)}</Legenda>
            <SquadColor color={theme!.colors.success} />
            <Legenda>{EStatusEnvioToString(EStatusEnvio.Lido)}</Legenda>
        </Container>
    )
}

export default QrCodeLegenda