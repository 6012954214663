import styled from 'styled-components'

export const Container = styled.div`
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colors.primary};
    padding: 30px;
`

export const Logo = styled.h1`
    color: ${props => props.theme.colors.white};
    margin-bottom: 30px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`

export const Form = styled.form`
    width: 350px;
    height: auto;
    padding: 30px;
    border-radius: 10px;
    background-color: ${props => props.theme.colors.tertiary};
`

export const LabelAvisos =styled.span`
    color: ${props => props.theme.colors.white};
    text-align: center;
    margin-bottom: 30px;
`