import { Container, TitleContent } from "./styles"
import { ITitleProps } from "./types"

const Title: React.FC<ITitleProps> = ({ children, color, size }) => {
    return(
        <Container>
            <TitleContent color={color} size={size}>
                {children}
            </TitleContent>
        </Container>
    )
}

export default Title