import { ThemeProvider } from 'styled-components'
import GlobalStyles from "./styles/GlobalStyles"
import Rotas from "./routes"
import { useToggleTheme } from './hooks/theme'

const App: React.FC = () => {
    const { theme } = useToggleTheme()

    return(
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Rotas />
        </ThemeProvider>
    )
}

export default App
