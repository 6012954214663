export enum EStatusEnvio {
    NaoEnviado = 1,
    Enviado = 2,
    Lido = 3,
}

export const EStatusEnvioToString = (status: EStatusEnvio): string => {
    switch(status) {
        case EStatusEnvio.NaoEnviado:
            return 'Não Enviado'
        case EStatusEnvio.Enviado:
            return 'Enviado'
        case EStatusEnvio.Lido:
            return 'Lido'
        default:
            return ''
    }
}