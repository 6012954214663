import { Container } from "./styles"
import { IFormProps } from "./types"

const Form: React.FC<IFormProps> = ({ children }) => {
    return(
        <Container>
            {children}
        </Container>
    )
}

export default Form