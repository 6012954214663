import { Container } from "./styles"
import { IContentProps } from "./types"

const Content: React.FC<IContentProps> = ({ children }) => {
    return(
        <Container>
            {children}
        </Container>
    )
}

export default Content