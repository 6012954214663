import { useTheme } from "styled-components"
import Button from "../../components/Button"
import GroupButtons from "../../components/GroupButtons"
import { Container } from "./styles"
import { IEventoHistoricoProps } from "./types"
import { useNavigate, useParams } from "react-router-dom"
import List from "../../components/List"
import * as EventoHistoryService from '../../services/EventoHistory/EventoHistoryService'
import { useState, useEffect } from "react"
import { EventoHistory } from "../../models"
import { toast } from "react-toastify"
import { errorHandle } from "../../utils/errorHandle"
import Loader from "../../components/Loader"
import CardEventoHistory from "../../components/CardEventoHistory"
import Title from "../../components/Title"

const EventoHistorico: React.FC<IEventoHistoricoProps> = () => {
    const [historicos, setHistoricos] = useState<EventoHistory[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const theme = useTheme()
    const { eventoId, eventoDescricao } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        getHistorico()
        // eslint-disable-next-line
    }, [])

    const getHistorico = async (): Promise<void> => {
        setLoading(true)
        await EventoHistoryService.getByEventoId(eventoId!)
            .then((res) => {
                setHistoricos(res.data.data)
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    return(
        <>
            {
                loading
                    ? <Loader />
                    : <Container>
                        <Title color={theme!.colors.warning} size={30}>Histórico de Eventos</Title>
                        <Title color={theme!.colors.info} size={20}>{eventoDescricao!}</Title>
                        <GroupButtons>
                            <Button 
                                $backgroundColor={theme!.colors.warning}
                                type="button"
                                onClick={() => navigate('/')}
                            >
                                Voltar
                            </Button>
                        </GroupButtons>
                        <List>
                            {
                                historicos.map(historico => (
                                    <CardEventoHistory 
                                        key={historico.id}
                                        historico={historico} 
                                    />
                                ))
                            }
                        </List>
                    </Container>
            }
        </>
    )
}

export default EventoHistorico