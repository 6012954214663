import { useTheme } from "styled-components"
import { Container, LabelCard, ActionsButtons, IconButton, GroupLabels } from "./styles"
import { ICardEventoProps } from "./types"
import { MdEdit, MdDelete, MdList, MdAccessTime, MdBarChart } from 'react-icons/md'
import { EStatus } from "../../models"
import { format } from "date-fns"
import { useAuthContext } from "../../hooks/auth"

const CardEvento: React.FC<ICardEventoProps> = ({ evento, editar, listarConvidados, remover, historico, score }) => {
    const theme = useTheme()
    const { isAdmin } = useAuthContext()

    return(
        <Container $tagColor={evento.status === EStatus.Ativo ? theme!.colors.success : theme!.colors.warning}>
            <GroupLabels>
                <LabelCard>Descrição: {evento.descricao}</LabelCard>
                <LabelCard>Data: {format(new Date(evento.data), 'dd/MM/yyyy')}</LabelCard>
            </GroupLabels>
            <ActionsButtons>
                <IconButton hidden={!isAdmin} onClick={() => editar(evento.id)}>
                    <MdEdit color={theme!.colors.info} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                </IconButton>
                <IconButton onClick={() => listarConvidados(evento.id, evento.descricao)}>
                    <MdList color={theme!.colors.success} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                </IconButton>
                <IconButton hidden={!isAdmin} onClick={() => remover(evento.id, evento.descricao)}>
                    <MdDelete color={theme!.colors.warning} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                </IconButton>
                <IconButton hidden={!isAdmin} onClick={() => historico(evento.id, evento.descricao)}>
                    <MdAccessTime color={theme!.colors.info} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                </IconButton>
                <IconButton hidden={!isAdmin} onClick={() => score(evento.id, evento.descricao)}>
                    <MdBarChart color={theme!.colors.success} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                </IconButton>
            </ActionsButtons>
        </Container>
    )
}

export default CardEvento