import { parseISO } from "date-fns";
import { utcToZonedTime , format } from "date-fns-tz"

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const dateForInput = (data: Date | undefined | null): string => {
    const dateLocal = data ? utcToZonedTime(data, timeZone) : null
    return dateLocal ? format(dateLocal!, 'yyyy-MM-dd') : ''
}

export const inputToDate = (value: string | undefined | null): Date | null => {
    return value ? parseISO(value) : null
} 

export const dataForFilter = (data: Date | undefined | null): string => {
    const dateLocal = data ? utcToZonedTime(data, timeZone) : null
    return format(dateLocal ?? new Date(), 'yyyy-MM-dd 00:00:00')
}