import { MoonLoader } from "react-spinners"
import { Container } from "./styles"
import { ILoaderProps } from "./types"
import { useTheme } from "styled-components"

const Loader: React.FC<ILoaderProps> = () => {
    const theme = useTheme()

    return(
        <Container>
            <MoonLoader size={100} color={theme!.colors.info} />
        </Container>
    )
}

export default Loader