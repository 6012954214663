import Api from "../../config/Api"
import { CreateResponse, Evento, GetResponse } from "../../models"

export const criar = async (model: Evento) => {
  return await Api.post<CreateResponse>('Evento', model)
}

export const atualizar = async (model: Evento) => {
  return await Api.put<void>('Evento', model)
}

export const listar = async (filtro: string, page: number, rowsPerPage: number) => {
  return await Api.get<GetResponse<Evento[]>>(
    `Evento?Offset=${page * rowsPerPage}&Limit=${rowsPerPage}&SortDesc=true&OrderBy=data${filtro}`,
  )
}

export const buscar = async (id: string) => {
  return await Api.get<Evento>(`Evento/${id}`)
}

export const remover = async (id: string) => {
  return await Api.delete<void>(`Evento/${id}`)
}
