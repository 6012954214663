import { useTheme } from "styled-components"
import { ActionsButtons, Container, GroupLabels, IconButton, LabelCard } from "./styles"
import { ICardMensagemProps } from "./types"
import { useAuthContext } from "../../hooks/auth"
import { EStatus } from "../../models"
import { MdDelete, MdEdit } from "react-icons/md"

const CardMensagem: React.FC<ICardMensagemProps> = ({ mensagem, editar, remover }) => {
    const theme = useTheme()
    const { isAdmin } = useAuthContext()
    
    return(
        <Container $tagColor={mensagem.status === EStatus.Ativo ? theme!.colors.success : theme!.colors.warning}>
            <GroupLabels>
                <LabelCard>Descrição: {mensagem.descricao}</LabelCard>
            </GroupLabels>
            <ActionsButtons>
                <IconButton hidden={!isAdmin} onClick={() => editar(mensagem.id)}>
                    <MdEdit color={theme!.colors.info} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                </IconButton>
                <IconButton hidden={!isAdmin} onClick={() => remover(mensagem.id, mensagem.descricao)}>
                    <MdDelete color={theme!.colors.warning} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                </IconButton>
            </ActionsButtons>
        </Container>
    )
}

export default CardMensagem