import styled from 'styled-components'
import ReactPaginate, { ReactPaginateProps } from 'react-paginate'

export const Container = styled(ReactPaginate)<ReactPaginateProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: bold;
    list-style: none;
    padding: 25px 0;

    .active {
        background-color: ${props => props.theme.colors.info};
    }

    > li {
        border-radius: 10px;
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.tertiary};

        > a {
            cursor: pointer;
            display: block;
            padding: 10px 15px;
            border-radius: 10px;
        }

        &:hover {
            background-color: ${props => props.theme.colors.info};
        }
    }
`