import { useTheme } from "styled-components"
import Title from "../../components/Title"
import { Container } from "./styles"
import { IConfFormGoogleProps } from "./types"
import Form from "../../components/Form"
import Input from "../../components/Input"
import { useState, useEffect } from "react"
import GroupButtons from "../../components/GroupButtons"
import Button from "../../components/Button"
import { useNavigate } from "react-router-dom"
import Loader from "../../components/Loader"
import { EStatus, FormGoogle } from "../../models"
import * as FormGoogleService from '../../services/FormGoogle/FormGoogleService'
import { toast } from "react-toastify"
import { errorHandle } from "../../utils/errorHandle"
import { guidEmpty } from "../../utils/guidEmpty"
import { removerAcentos } from "../../utils/removerAcentos"

const ConfFormGoogle: React.FC<IConfFormGoogleProps> = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [formGoogle, setFormGoogle] = useState<FormGoogle>({
        id: guidEmpty(),
        nome: '',
        celular: '',
        status: EStatus.Ativo
    } as FormGoogle)

    const theme = useTheme()
    const navigate = useNavigate()

    useEffect(() => {
        getFormGoogle()
    }, [])

    const getFormGoogle = async (): Promise<void> => {
        setLoading(true)
        await FormGoogleService.listar()
            .then((res) => {
                if (res && res.data && res.data.data.length > 0)
                    setFormGoogle(res.data.data[0])
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const handleNome = (nome: string): void => {
        setFormGoogle((prevState) => ({
            ...prevState,
            nome: removerAcentos(nome.toUpperCase())
        }))
    }

    const handleCelular = (celular: string): void => {
        setFormGoogle((prevState) => ({
            ...prevState,
            celular: removerAcentos(celular.toUpperCase())
        }))
    }

    const handleSubmit = async (): Promise<void> => {
        if (formGoogle.id === guidEmpty())
            await criar()
        else
            await atualizar()
    }

    const atualizar = async (): Promise<void> => {
        setLoading(true)
        await FormGoogleService.atualizar(formGoogle)
            .then(() => {
                toast.success('Formulário Google atualizado com sucesso')
                navigate('/')
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const criar = async (): Promise<void> => {
        setLoading(true)
        await FormGoogleService.criar(formGoogle)
            .then(() => {
                toast.success('Formulário Google criado com sucesso')
                navigate('/')
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    return(
        <>
            {
                loading
                ? <Loader />
                : <Container>
                    <Title color={theme!.colors.warning} size={30}>Configuração Form. Google</Title>
                    <Form>
                        <Input
                            width={45}
                            widthMobile={93}
                            label="Descrição Campo Nome *"
                            placeholder="Descrição Campo Nome *"
                            type="text"
                            value={formGoogle.nome}
                            onChange={(e) => handleNome(e.target.value)}
                        />
                        <Input
                            width={45}
                            widthMobile={93}
                            label="Descrição Campo Celular *"
                            placeholder="Descrição Campo Celular *"
                            type="text"
                            value={formGoogle.celular}
                            onChange={(e) => handleCelular(e.target.value)}
                        />
                    </Form>
                    <GroupButtons>
                        <Button 
                            $backgroundColor={theme!.colors.info} 
                            type="button" 
                            onClick={handleSubmit}
                        >
                            Salvar
                        </Button>
                        <Button 
                            $backgroundColor={theme!.colors.warning} 
                            type="button" 
                            onClick={() => navigate('/')}
                        >
                            Voltar
                        </Button>
                    </GroupButtons>
                </Container>
            }
        </>
    )
}

export default ConfFormGoogle