import { Routes, Route, Navigate } from 'react-router-dom'
import ListaEvento from '../pages/Evento/ListaEvento'
import Layout from '../components/Layout'
import CadastroEvento from '../pages/Evento/CadastroEvento'
import ListaConvidado from '../pages/Convidado/ListaConvidado'
import CadastroConvidado from '../pages/Convidado/CadastroConvidado'
import Usuario from '../pages/Usuario'
import EventoHistorico from '../pages/EventoHistorico'
import ConvidadoHistorico from '../pages/ConvidadoHistorico'
import ConfFormGoogle from '../pages/ConfFormGoogle'
import ListaMensagem from '../pages/Mensagem/ListaMensagem'
import CadastroMensagem from '../pages/Mensagem/CadastroMensagem'
import MensagemHistorico from '../pages/MensagemHistorico'
import Score from '../pages/Score'

const AppRoutes: React.FC = () => (
    <Layout>
        <Routes>
            <Route path='/' element={<ListaEvento />} />
            <Route path='/evento/:id' element={<CadastroEvento />} />
            <Route path='/evento-history/:eventoId/:eventoDescricao' element={<EventoHistorico />} />
            <Route path='/convidado/:eventoId/:eventoDescricao' element={<ListaConvidado />} />
            <Route path='/convidado/:eventoId/:eventoDescricao/:id' element={<CadastroConvidado />} />
            <Route path='/convidado-history/:eventoId/:eventoDescricao/:convidadoId/:convidadoNome' element={<ConvidadoHistorico />} />
            <Route path='/usuario' element={<Usuario />} />
            <Route path='/form-google' element={<ConfFormGoogle />} />
            <Route path='/mensagem' element={<ListaMensagem />} />
            <Route path='/mensagem/:id' element={<CadastroMensagem />} />
            <Route path='/mensagem-history/:eventoId/:eventoDescricao/:convidadoId/:convidadoNome' element={<MensagemHistorico />} />
            <Route path='/score/:eventoId/:eventoDescricao' element={<Score />} />
            <Route path='*' element={<Navigate to='/' />} />
        </Routes>
    </Layout>
)

export default AppRoutes