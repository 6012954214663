import { createContext, useState, useContext } from 'react'
import { IChildrenProps } from '../models'
import { ConstStorage } from '../utils/constStorage'
import * as AuthServices from '../services/Auth/AuthService'
import { toast } from 'react-toastify'
import { errorHandle } from '../utils/errorHandle'
import JwtDecode from 'jwt-decode'
import Api from '../config/Api'

interface IAuthContext {
    logged: boolean,
    loading: boolean,
    user: string,
    isAdmin: boolean,
    redirect: boolean,
    login: (email: string, senha: string) => Promise<void>,
    register: (email: string, senha: string, confirmarSenha: string) => Promise<void>,
    logout: () => void,
    enviarEmail: (email: string) => Promise<void>,
    resetarSenha: (email: string, senha: string, confirmarSenha: string, token: string) => Promise<void>
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext)

const AuthProvider: React.FC<IChildrenProps> = ({ children }) => {
    const token = localStorage.getItem(ConstStorage.checkMyListLogged)

    const getUser = (token: string): string => {
        const decode: any = JwtDecode(token)
        return decode.unique_name;
    }

    const getIsAdmin = (token: string): boolean => {
        const decode: any = JwtDecode(token)
        return decode.Permissao === 'Admin';
    }

    const [loading, setLoading] = useState<boolean>(false)
    const [user, setUser] = useState<string>(() => token ? getUser(JSON.parse(token)) : '')
    const [isAdmin, setIsAdmin] = useState<boolean>(() => token ? getIsAdmin(JSON.parse(token)) : false)
    const [logged, setLogged] = useState<boolean>(() => !!token)
    const [redirect, setRedirect] = useState<boolean>(false)

    const login = async (email: string, senha: string): Promise<void> => {
        setLoading(true)
        await AuthServices.login({ email, senha })
            .then((res) => {
                if(res && res.data && res.data.token) {
                    localStorage.setItem(ConstStorage.checkMyListLogged, JSON.stringify(res.data.token))
                    setUser(getUser(res.data.token))
                    setIsAdmin(getIsAdmin(res.data.token))
                    setLogged(true)
                    toast.success('Login efetuado com sucesso!')
                }
            })
            .catch(err => {
                toast.error(errorHandle(err, 'Erro ao efetuar login'))
            })
            .finally(() => setLoading(false))
    }

    const register = async (email: string, senha: string, confirmacaoSenha: string): Promise<void> => {
        setLoading(true)
        await AuthServices.register({ email, senha, confirmacaoSenha })
            .then((res) => {
                toast.success(res.data)
            })
            .catch(err => {
                toast.error(errorHandle(err, 'Erro ao efetuar login'))
            })
            .finally(() => setLoading(false))
    }

    const logout = (): void => {
        localStorage.removeItem(ConstStorage.checkMyListLogged)
        setLogged(false)
        setIsAdmin(false)
        setUser('')
    }

    const enviarEmail = async (email: string): Promise<void> => {
        setLoading(true)
        await AuthServices.enviarEmail({ email })
            .then((res) => {
                toast.success(res.data)
            })
            .catch(err => {
                toast.error(errorHandle(err, 'Erro ao enviar email'))
            })
            .finally(() => setLoading(false))
    }

    const resetarSenha = async (email: string, senha: string, confirmacaoSenha: string, token: string) => {
        setLoading(true)
        await AuthServices.resetarSenha({ email, senha, confirmacaoSenha, token })
            .then((res) => {
                toast.success(res.data)
                setRedirect(true)
            })
            .catch(err => {
                toast.error(errorHandle(err, 'Erro ao alterar senha'))
                setRedirect(false)
            })
            .finally(() => setLoading(false))
    }

    Api.interceptors.request.use((config) => {
        const token = localStorage.getItem(ConstStorage.checkMyListLogged)
    
        if(token)
            config.headers['Authorization'] = `Bearer ${JSON.parse(token)}`
    
        return config
    })
    
    Api.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response) {
                const status = error.response.status;
                if (status === 401) {
                    responseError('Usuário não autorizado.')
                } else if (status === 403) {
                    responseError('Acesso proibido.')
                }
            }
    
            return Promise.reject(error);
        }
    )
    
    const responseError = (message: string): void => {
        toast.error(message)
        logout()
    }

    return(
        <AuthContext.Provider value={{logged, loading, user, isAdmin, redirect, login, register, logout, enviarEmail, resetarSenha}}>
            {children}
        </AuthContext.Provider>
    )
}

const useAuthContext = (): IAuthContext => {
    const context = useContext(AuthContext)
    return context
}

export {AuthProvider, useAuthContext}