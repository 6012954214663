import { useTheme } from "styled-components"
import Form from "../../../components/Form"
import GroupButtons from "../../../components/GroupButtons"
import List from "../../../components/List"
import Title from "../../../components/Title"
import { Container, Totalizador } from "./styles"
import { IListaConvidadoProps } from "./types"
import Input from "../../../components/Input"
import Select from "../../../components/Select"
import { Convidado, EStatus, Item, TotalizadorConvidadosResponse } from "../../../models"
import Button from "../../../components/Button"
import { useNavigate, useParams } from "react-router"
import { useEffect, useState } from "react"
import Loader from "../../../components/Loader"
import CardConvidado from "../../../components/CardConvidado"
import { enumToArray } from "../../../utils/enumToArray"
import * as ConvidadoService from '../../../services/Convidado/ConvidadoService'
import { confirmAlert } from "react-confirm-alert"
import { toast } from "react-toastify"
import { errorHandle } from "../../../utils/errorHandle"
import { montaQuery } from "../../../utils/montaQuery"
import Pagination from "../../../components/Pagination"
import UploadButton from "../../../components/UploadButton"
import { useAuthContext } from "../../../hooks/auth"
import { guidEmpty } from "../../../utils/guidEmpty"
import QrCodeLegenda from "../../../components/QrCodeLegenda"
import { removerAcentos } from "../../../utils/removerAcentos"
import Modal from "../../../components/Modal"
import * as MensagemService from '../../../services/Mensagem/MensagemService'

const ListaConvidado: React.FC<IListaConvidadoProps> = () => {
    const [nome, setNome] = useState<string>('')
    const [mesa, setMesa] = useState<string>('')
    const [entrou, setEntrou] = useState<string>('0')
    const [ativo, setAtivo] = useState<string>('0')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mensagens, setMensagens] = useState<Item[]>([])
    const [mensagemId, setMensagemId] = useState<string>('')
    const [convidadoId, setConvidadoId] = useState<string>('')
    
    const [loading, setLoading] = useState<boolean>(false)
    const [convidados, setConvidados] = useState<Convidado[]>([])
    const [totalizador, setTotalizador] = useState<TotalizadorConvidadosResponse>({ total: 0, entrou: 0 })

    const theme = useTheme()
    const { isAdmin } = useAuthContext()
    const navigate = useNavigate()
    const { eventoId, eventoDescricao } = useParams()

    const [filtros, setFiltros] = useState<string>(`&Condition=eventoId="${eventoId}"`)
    const [page, setPage] = useState<number>(0)
    const [totalCount, setTotalCount] = useState<number>(0)
    const rowsPerPage = 50

    const listaStatus = [{ id: '0', descricao: 'Todos' }, ...enumToArray(EStatus)]

    const selectValues: Item[] = [
        { id: '0', descricao: 'Todos' },
        { id: '1', descricao: 'Sim' },
        { id: '2', descricao: 'Não' }
    ]

    useEffect(() => {
        if(isModalOpen){
            setMensagemId('');
            getMensagens();
        }
        else {
            setConvidadoId('')
        }
    }, [isModalOpen])

    useEffect(() => {
        getTotalizador(eventoId ?? '')
    }, [eventoId])

    useEffect(() => {
        getConvidados(filtros, page, rowsPerPage)
    }, [filtros, page, rowsPerPage])

    const getConvidados = async (filtro: string, page: number, rowsPerPage: number) => {
        setLoading(true)
        await ConvidadoService.listar(filtro, page, rowsPerPage)
            .then(res => {
                setConvidados(res.data.data)
                setTotalCount(res.data.totalCount)
            })
            .catch(err => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const getTotalizador = async (eventoId: string): Promise<void> => {
        await ConvidadoService.buscarTotalizador(eventoId)
            .then(res => setTotalizador(res.data))
            .catch(err => toast.error(errorHandle(err)))
    }

    const getMensagens = async (): Promise<void> => {
        await MensagemService.buscarAtivos()
            .then(res => {
                const items: Item[] = [{ id: '', descricao: '' }];
                res.data.data.forEach(m => {
                    items.push({ id: m.id, descricao: m.descricao } as Item)
                })
                setMensagens(items);
            })
            .catch((err) => toast.error(errorHandle(err)))
    }

    const handleSubmit = (): void => {
        const params = handlerFilter()
        setFiltros(params)
    }

    const limparFiltros = () => {
        setNome('')
        setMesa('')
        setEntrou('0')
        setAtivo('0')
        setFiltros(`&Condition=eventoId="${eventoId}"`)
    }

    const handlerFilter = (): string => {
        let params = ''
        if (eventoId && eventoId !== '' && eventoId !== 'new') {
            params += montaQuery(params)
            params += `eventoId="${eventoId}"`
        }
        if (nome && nome !== '') {
            params += montaQuery(params)
            params += `nome.contains("${nome}")`
        }
        if (mesa && mesa !== '') {
            params += montaQuery(params)
            params += `mesa.contains("${mesa}")`
        }
        if (entrou !== '0') {
            params += montaQuery(params)
            params += `entrou == ${entrou === '1' ? 'true' : 'false'}`
        }
        if (ativo !== '0') {
            params += `&Active=${ativo === '1' ? 'true' : 'false'}`
        }
        if (params !== '') 
            setPage(0)

        return params
    }

    const handleEditar = (convidadoId: string): void => {
        navigate(`/convidado/${eventoId}/${eventoDescricao}/${convidadoId}`)
    }

    const handleEnviarQrCode = async (convidadoId: string, nome: string): Promise<void> => {
        confirmAlert({
            title: 'Envio de Qr Code',
            message: `Deseja enviar o Qr Code para o convidado ${nome}?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        setLoading(true)
                        await ConvidadoService.enviarQrCode(convidadoId)
                            .then(() => toast.success(`Qr code enviado com sucesso para ${nome}`))
                            .catch((err) => toast.error(errorHandle(err)))
                            .finally(() => setLoading(false))
                    }
                },
                {
                    label: 'Não',
                    onClick: () => {}
                }
            ]
        })
    }

    const handleRemover = async (convidadoId: string, nome: string): Promise<void> => {
        confirmAlert({
            title: 'Deletar convidado',
            message: `deseja mesmo deletar o convidado ${nome}?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        setLoading(true)
                        await ConvidadoService.remover(convidadoId)
                            .then(() => {
                                toast.success(`O convidado ${nome} foi deletado com sucesso`)
                                getConvidados(filtros, page, rowsPerPage)
                            })
                            .catch((err) => toast.error(errorHandle(err)))
                            .finally(() => setLoading(false))
                    }
                },
                {
                    label: 'Não',
                    onClick: () => {}
                }
            ]
        })
    }

    const handleExportar = async (): Promise<void> => {
        confirmAlert({
            title: 'Download',
            message: 'Deseja fazer o download da lista?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        setLoading(true);
                        await ConvidadoService.downloadTemplate(eventoId ?? guidEmpty())
                            .then((res) => {
                                const url = window.URL.createObjectURL(new Blob([res.data]))
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'Lista de Convidados.xlsx');
                                document.body.appendChild(link);
                                link.click()
                            })
                            .catch((err) => toast.error(errorHandle(err)))
                            .finally(() => setLoading(false))
                    }
                },
                {
                    label: 'Não',
                    onClick: () => {}
                }
            ]
        })
    }
    
    const handleImportar = async (arquivo: File): Promise<void> => {
        if(arquivo != null) {
            confirmAlert({
                title: 'Tipo de Layout',
                message: 'Qual o layout do arquivo a ser importado?',
                buttons: [
                    {
                        label: 'Padrão',
                        onClick: async () => await importarListaComum(arquivo)
                    },
                    {
                        label: 'Form. Google',
                        onClick: async () => await importarFormGoogle(arquivo)
                    }
                ]
            })
        }
    }

    const importarFormGoogle = async (arquivo: File): Promise<void> => {
        setLoading(true)
        const formData = new FormData()
        formData.append('arquivo', arquivo)
        await ConvidadoService.uploadFormGoogle(eventoId!, formData)
            .then((res) => {
                toast.success(res.data)
                getConvidados(filtros, page, rowsPerPage)
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const importarListaComum = async (arquivo: File): Promise<void> => {
        setLoading(true)
        const formData = new FormData()
        formData.append('arquivo', arquivo)
        await ConvidadoService.uploadListaConvidados(eventoId!, formData)
            .then((res) => {
                toast.success(res.data)
                getConvidados(filtros, page, rowsPerPage)
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const handleHistorico = (convidadoId: string, nome: string): void => {
        navigate(`/convidado-history/${eventoId}/${eventoDescricao}/${convidadoId}/${nome}`)
    }

    const handleEnviarQrCodeLote = () => {
        confirmAlert({
            title: 'Enviar Qr Codes',
            message: 'Deseja enviar Qr Codes em lote para todos os convidados da lista?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        setLoading(true)
                        await ConvidadoService.enviarQrCodeLote(eventoId ?? guidEmpty())
                            .then(() => {
                                toast.success('Qr Codes enviados com sucesso')
                                getConvidados(filtros, page, rowsPerPage)
                            })
                            .catch((err) => toast.error(errorHandle(err)))
                            .finally(() => setLoading(false))
                    }
                },
                {
                    label: 'Não',
                    onClick: () => {}
                }
            ]
        })
    }

    const enviarMensagens = async () => {
        setLoading(true)
        await MensagemService.enviarMensagens(eventoId!, mensagemId)
            .then(res => {
                toast.success(`Mensagens enviadas com sucesso`)
                closeModal()
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const enviarMensagem = async (convidadoId: string) => {
        setLoading(true)
        await MensagemService.enviarMensagem(convidadoId, mensagemId)
            .then(res => {
                toast.success(`Mensagem enviada com sucesso`)
                closeModal()
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const handleEnviarMensagem = (convidadoId: string) => {
        setConvidadoId(convidadoId)
        openModal()
    }

    const handleHistoricoMensagem = (convidadoId: string, nome: string) => {
        navigate(`/mensagem-history/${eventoId}/${eventoDescricao}/${convidadoId}/${nome}`)
    }

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return(
        <>
            {
                loading
                    ? <Loader />
                    : <Container>
                        <Title 
                            color={theme!.colors.warning}
                            size={30}
                        >
                            Lista de Convidados
                        </Title>
                        <Title 
                            color={theme!.colors.info}
                            size={20}
                        >
                            {eventoDescricao!}
                        </Title>
                        <Form>
                            <Input 
                                width={45}
                                label="Nome" 
                                placeholder="Nome" 
                                type='text' 
                                value={nome} 
                                onChange={(e) => setNome(removerAcentos(e.target.value?.toUpperCase()))} 
                            />
                            <Input 
                                width={45}
                                widthMobile={31}
                                label="Mesa" 
                                placeholder="Mesa" 
                                type='text' 
                                value={mesa} 
                                onChange={(e) => setMesa(e.target.value?.toUpperCase())} 
                            />
                            <Select 
                                width={45}
                                widthMobile={31}
                                label="Entrou?" 
                                itens={selectValues} 
                                value={entrou} 
                                onChange={(e) => setEntrou(e.target.value)} 
                            />
                            <Select 
                                width={45}
                                widthMobile={31}
                                label="Status" 
                                itens={listaStatus} 
                                value={ativo} 
                                onChange={(e) => setAtivo(e.target.value)} 
                            />
                        </Form>
                        <GroupButtons>
                            <Button 
                                $backgroundColor={theme!.colors.info}
                                type="button"
                                onClick={handleSubmit}
                            >
                                Buscar
                            </Button>
                            <Button 
                                $backgroundColor={theme!.colors.warning} 
                                type="button" 
                                onClick={limparFiltros}
                            >
                                Limpar
                            </Button>
                        </GroupButtons>
                        <GroupButtons>
                            <Button 
                                $backgroundColor={theme!.colors.success} 
                                type="button"
                                onClick={() => navigate(`/convidado/${eventoId}/${eventoDescricao}/new`)}
                            >
                                Novo
                            </Button>
                            <Button
                                $backgroundColor={theme!.colors.success} 
                                type="button"
                                hidden={!isAdmin}
                                onClick={handleExportar}
                            >
                                Exportar Convidados
                            </Button>
                            <UploadButton 
                                $backgroundColor={theme!.colors.success} 
                                hidden={!isAdmin}
                                onFileUpload={handleImportar}
                            >
                                Importar Excel
                            </UploadButton>
                            <Button
                                $backgroundColor={theme!.colors.info}
                                type="button"
                                hidden={!isAdmin}
                                onClick={handleEnviarQrCodeLote}
                            >
                                Enviar Qr Codes
                            </Button>
                            <Button
                                $backgroundColor={theme!.colors.info}
                                type="button"
                                hidden={!isAdmin}
                                onClick={openModal}
                            >
                                Enviar Mensagens
                            </Button>
                            <Button
                                $backgroundColor={theme!.colors.warning} 
                                type="button"
                                onClick={() => navigate('/')}
                            >
                                Voltar
                            </Button>
                        </GroupButtons>
                        { isAdmin ? <QrCodeLegenda /> : <></> }
                        <List>
                            {convidados.map(convidado => (
                                <CardConvidado 
                                    key={convidado.id} 
                                    convidado={convidado} 
                                    editar={handleEditar} 
                                    enviarQrCode={handleEnviarQrCode}
                                    remover={handleRemover} 
                                    historico={handleHistorico}
                                    enviarMensagem={handleEnviarMensagem}
                                    historicoMensagem={handleHistoricoMensagem}
                                />
                            ))}
                        </List>
                        <Totalizador>Total de Convidados: {totalizador.total}</Totalizador>
                        <Totalizador>Qtde Entrou: {totalizador.entrou}</Totalizador>
                        <Pagination 
                            page={page} 
                            rowsPerPage={rowsPerPage} 
                            totalCount={totalCount} 
                            handlePage={(e) => setPage(e.selected)} 
                        />

                        <Modal isOpen={isModalOpen} onClose={closeModal} title={convidadoId !== '' ? 'Enviar mensagem para o convidado' : 'Enviar mensagens para todos'}>
                            <Form>
                                <Select 
                                    width={93}
                                    widthMobile={93}
                                    label="Mensagens" 
                                    itens={mensagens}
                                    value={mensagemId}
                                    onChange={(e) => setMensagemId(e.target.value)}
                                />
                            </Form>
                            <GroupButtons>
                                <Button
                                    $backgroundColor={theme!.colors.info}
                                    type="button"
                                    onClick={() => convidadoId !== '' ? enviarMensagem(convidadoId) : enviarMensagens()}
                                >
                                    Enviar
                                </Button>
                                <Button
                                    $backgroundColor={theme!.colors.warning} 
                                    type="button"
                                    onClick={closeModal}
                                >
                                    Fechar
                                </Button>
                            </GroupButtons>
                        </Modal>

                    </Container>
            }
        </>
    )
}

export default ListaConvidado