import { Container, SelectField, SelectLabel } from "./styles"
import { ISelectProps } from "./types"

const Select: React.FC<ISelectProps> = ({ label, itens, width, widthMobile, ...props }) => {
    return(
        <Container width={width} widthMobile={widthMobile}>
            {label && <SelectLabel>{label}:</SelectLabel>}
            <SelectField {...props}>
                {itens.map(item => (<option key={item.id} value={item.id}>{item.descricao}</option>))}
            </SelectField>
        </Container>
    )
}

export default Select
