import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

export const Table = styled.table`
    border: 1px solid #fff;
    border-radius: 10px;
    text-align: center;
    width: 100%;
`

export const Thead = styled.thead`
    background-color: ${props => props.theme.colors.tertiary};
`

export const TBody = styled.tbody`
    background-color: ${props => props.theme.colors.secondary};
`

export const Tr = styled.tr`
    line-height: 25px;
`

export const Th = styled.th`
    border: 1px solid #fff;
`

export const Td = styled.td`
    border: 1px solid #fff;
`