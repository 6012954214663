import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from './hooks/theme';
import { AuthProvider } from './hooks/auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider>
      <ToastContainer autoClose={3000} position='bottom-center' />
      <AuthProvider>
        <App />
      </AuthProvider>
  </ThemeProvider>
);
