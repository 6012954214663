import Api from '../../config/Api'
import { MensagemHistory, GetResponse } from '../../models'

export const getByConvidadoId = async (convidadoId: string) => {
    return await Api.get<GetResponse<MensagemHistory[]>>('/MensagemHistory' +
        '?Offset=0' +
        '&Limit=-1' +
        '&Active' +
        '&OrderBy=dataCriacao' +
        '&SortDesc=false' +
        '&Include=Mensagem' +
        `&Condition=convidadoId="${convidadoId}"`
    )
}