import { useTheme } from "styled-components"
import { EStatus } from "../../models"
import { Container, GroupContainer, GroupLabels, LabelCard } from "./styles"
import { ICardConvidadoHistoryProps } from "./types"
import { celularInsertMask } from "../../utils/maskCelular"
import { MdCheck, MdClose, MdQrCode } from "react-icons/md"
import { format } from "date-fns"
import { useToggleTheme } from "../../hooks/theme"

const CardConvidadoHistory: React.FC<ICardConvidadoHistoryProps> = ({ historico }) => {
    const theme = useTheme()
    const { colorEnvio } = useToggleTheme()

    return(
        <Container $tagColor={historico.status === EStatus.Ativo ? theme!.colors.success : theme!.colors.warning}>
            <GroupContainer>
                <GroupLabels>
                    <LabelCard>Data Criação: {format(new Date(historico.dataCriacao!), 'dd/MM/yyyy HH:mm:ss')}</LabelCard>
                    <LabelCard>Usuário: {historico.usuario}</LabelCard>
                </GroupLabels>
                <GroupLabels>
                    <LabelCard>Nome: {historico.nome}</LabelCard>
                    <LabelCard>celular: {celularInsertMask(historico.celular)}</LabelCard>
                    <LabelCard>Idade: {historico.idade}</LabelCard>
                </GroupLabels>
                <GroupLabels>
                    <LabelCard>Mesa: {historico.mesa}</LabelCard>
                    <LabelCard>
                        Confirmado?: {
                            historico.confirmado 
                                ? <MdCheck color={theme!.colors.info} size={30} style={{backgroundColor: theme!.colors.tertiary}} /> 
                                : <MdClose color={theme!.colors.warning} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                        }
                    </LabelCard>
                    <LabelCard>
                        Entrou?: {
                            historico.entrou 
                                ? <MdCheck color={theme!.colors.info} size={30} style={{backgroundColor: theme!.colors.tertiary}} /> 
                                : <MdClose color={theme!.colors.warning} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                        }
                    </LabelCard>
                    <LabelCard>
                        Entrou Qr Code?: {
                            historico.entrouQrCode 
                                ? <MdCheck color={theme!.colors.info} size={30} style={{backgroundColor: theme!.colors.tertiary}} /> 
                                : <MdClose color={theme!.colors.warning} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                        }
                    </LabelCard>
                    <MdQrCode color={colorEnvio(historico.statusEnvio)} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                </GroupLabels>
            </GroupContainer>
        </Container>
    )
}
export default CardConvidadoHistory