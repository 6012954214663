import styled from 'styled-components'
import { IUploadButtonStyleProps, IUploadContainer } from './types';

export const Container = styled.div<IUploadContainer>`
  margin-top: ${props => props.marginTop ? props.marginTop + 'px' : 0};

  @media (max-width: 768px) {
    width: 50%;
  }
`

export const HiddenInput = styled.input`
  display: none;
`;

export const Button = styled.label<IUploadButtonStyleProps>`
  padding: 10px 20px;
  background-color: ${props => props.$backgroundColor};
  color: ${props => props.theme.colors.white};
  font-size: 16px;
  font-weight: bolder;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;