import styled from 'styled-components'
import { ICardMensagemStyleProps, IIconButtonProps } from './types'

export const Container = styled.div<ICardMensagemStyleProps>`
    display: flex;
    flex: 1;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.tertiary};
    border-left: 10px solid ${props => props.$tagColor};
    border-radius: 10px;

    @media (max-width: 576px) {
        flex-direction: column;
    }
`

export const GroupLabels = styled.div`
    display: flex;
    flex: 1;
    gap: 10px;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 576px) {
        flex-direction: column;
    }
`

export const LabelCard = styled.span`
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
    font-size: 16px;
`

export const ActionsButtons = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    @media (max-width: 576px) {
        width: 100%;
        justify-content: space-around;
    }
`

export const IconButton = styled.button<IIconButtonProps>`
    display: ${props => props.hidden ? 'none' : 'flex'};
    justify-content: center;
    align-items: center;
`