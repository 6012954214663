import styled from 'styled-components'
import { IButtonStylesProps } from './types'

export const Container = styled.button<IButtonStylesProps>`
    margin: 7px 0;
    padding: 10px 30px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bolder;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.$backgroundColor};
    transition: opacity 0.3s;

    &:hover {
        opacity: 0.7;
    }
`