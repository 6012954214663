import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
`

export const FormGroup = styled.div`
    display: flex;
`

export const Totalizador = styled.h2`
    display: flex;
    justify-content: center;
    color: ${props => props.theme.colors.white};
`