import { Routes, Route, Navigate } from 'react-router-dom'
import Login from '../pages/Login'
import Registo from '../pages/Registo'
import ResetPassword from '../pages/ResetPassword'

const AuthRoutes = () => (
    <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/registro' element={<Registo />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='*' element={<Navigate to='/' />} />
    </Routes>
)

export default AuthRoutes