import { Container } from "./styles"
import { IListProps } from "./types"

const List: React.FC<IListProps> = ({ children }) => {
    return(
        <Container>
            {children}
        </Container>
    )
}

export default List