import Api from "../../config/Api"
import { AlterarSenhaDTO, AuthRetornoDTO, EnviarEmailResetSenhaDTO, LoginDTO, RegisterDTO } from "../../models"

export const register = async (registerDTO: RegisterDTO) => {
    return await Api.post<string>('Auth/register', registerDTO)
}

export const login = async (loginDTO: LoginDTO) => {
    return await Api.post<AuthRetornoDTO>('Auth/login', loginDTO)
}

export const enviarEmail = async (enviarEmailDTO: EnviarEmailResetSenhaDTO) => {
    return await Api.post<string>('Auth/enviar-email', enviarEmailDTO)
}

export const resetarSenha = async (alterarSenhaDTO: AlterarSenhaDTO) => {
    return await Api.post<string>('Auth/resetar-senha', alterarSenhaDTO)
}