import { useTheme } from "styled-components"
import Button from "../../components/Button"
import GroupButtons from "../../components/GroupButtons"
import List from "../../components/List"
import Title from "../../components/Title"
import { Container } from "./styles"
import { IUsuarioProps } from "./types"
import { useNavigate } from "react-router-dom"
import CardUsuario from "../../components/CardUsuario"
import { UserResponseDTO } from "../../models"
import { useState, useEffect } from "react"
import * as UserService from '../../services/Auth/UserService'
import { toast } from "react-toastify"
import { errorHandle } from "../../utils/errorHandle"
import Loader from "../../components/Loader"
import { confirmAlert } from "react-confirm-alert"

const Usuario: React.FC<IUsuarioProps> = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [usuarios, setUsuarios] = useState<UserResponseDTO[]>([])

    const theme = useTheme()
    const navigate = useNavigate()

    useEffect(() => {
        getUsuarios()
    }, [])

    const getUsuarios = async (): Promise<void> => {
        setLoading(true)
        await UserService.buscarUsuarios()
            .then((res) => {
                setUsuarios(res.data)
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const bloquearDesbloquearUsuario = async (id: string, bloqueado: boolean): Promise<void> => {
        confirmAlert({
            title: `${bloqueado ? 'Bloqueio' : 'Desbloqueio'} de usuários`,
            message: `Deseja mesmo ${bloqueado ? 'bloquear' : 'desbloquear'} o usuário?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        setLoading(true)
                        await UserService.bloquearDesbloquearUsuario(id, bloqueado)
                            .then((res) => {
                                toast.success(res.data)
                                getUsuarios()
                            })
                            .catch((err) => toast.error(errorHandle(err)))
                            .finally(() => setLoading(false))
                    }
                },
                {
                    label: 'Não',
                    onClick: () => {}
                }
            ]
        })
    }

    const deletarUsuario = async (id: string): Promise<void> => {
        confirmAlert({
            title: 'Deletar Usuário',
            message: 'deseja mesmo deletar usuário?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        setLoading(true)
                        await UserService.deletarUsuario(id)
                            .then((res) => {
                                toast.success(res.data)
                                getUsuarios()
                            })
                            .catch((err) => toast.error(errorHandle(err)))
                            .finally(() => setLoading(false))
                    }
                },
                {
                    label: 'Não',
                    onClick: () => {}
                }
            ]
        })
    }

    const alterarPermissao = async (id: string, isAdmin: boolean): Promise<void> => {
        confirmAlert({
            title: 'Alterar permissão do usuário',
            message: 'Deseja mesmo alterar a permissão do usuário?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        setLoading(true)
                        await UserService.alterarPermissaoUsuario(id, isAdmin)
                            .then((res) => {
                                toast.success(res.data)
                                getUsuarios()
                            })
                            .catch((err) => toast.error(errorHandle(err)))
                            .finally(() => setLoading(false))
                    }
                },
                {
                    label: 'Não',
                    onClick: () => {}
                }
            ]
        })
    }

    return(
        <>
            {
                loading
                    ? <Loader />
                    : <Container>
                        <Title 
                            color={theme!.colors.warning}
                            size={30}
                        >
                            Usuários
                        </Title>
                        <GroupButtons>
                            <Button 
                                $backgroundColor={theme!.colors.warning}
                                type="button"
                                onClick={() => navigate('/')}
                            >
                                Voltar
                            </Button>
                        </GroupButtons>
                        <List>
                            { usuarios.map(usuario => (
                                <CardUsuario 
                                    key={usuario.id} 
                                    usuario={usuario} 
                                    desbloquear={bloquearDesbloquearUsuario} 
                                    deletar={deletarUsuario} 
                                    alterarPermissao={alterarPermissao}
                                />
                            ))}
                        </List>
                    </Container>
            }
        </>
    )
}

export default Usuario