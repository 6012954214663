import { MdLockOpen, MdLock, MdDelete, MdThumbUp, MdThumbDown } from "react-icons/md"
import { ActionsButtons, Container, GroupLabels, IconButton, LabelCard } from "./styles"
import { ICardUsuarioProps } from "./types"
import { useTheme } from "styled-components"

const CardUsuario: React.FC<ICardUsuarioProps> = ({ usuario, desbloquear, deletar, alterarPermissao }) => {
    const theme = useTheme()

    return(
        <Container>
            <GroupLabels>
                <LabelCard>Usuário: {usuario.email}</LabelCard>
                <ActionsButtons>
                    <IconButton onClick={() => desbloquear(usuario.id, !usuario.bloqueado)}>
                        {
                            usuario.bloqueado
                            ? <MdLock color={theme!.colors.info} size={30} style={{ backgroundColor: theme!.colors.tertiary }} />
                            : <MdLockOpen color={theme!.colors.info} size={30} style={{ backgroundColor: theme!.colors.tertiary }} />
                        }
                    </IconButton>
                    <IconButton onClick={() => alterarPermissao(usuario.id, !usuario.isAdmin)}>
                        {
                            usuario.isAdmin
                            ? <MdThumbUp color={theme!.colors.success} size={30} style={{ backgroundColor: theme!.colors.tertiary }} />
                            : <MdThumbDown color={theme!.colors.warning} size={30} style={{ backgroundColor: theme!.colors.tertiary }} />
                        }
                    </IconButton>
                    <IconButton onClick={() => deletar(usuario.id)}>
                        <MdDelete color={theme!.colors.warning} size={30} style={{ backgroundColor: theme!.colors.tertiary }} />
                    </IconButton>
                </ActionsButtons>
            </GroupLabels>
        </Container>
    )
}

export default CardUsuario