import { Container, Label, ToggleConainer, ToggleLabel, ToggleSelector } from "./styles"
import { IToggleProps } from "./types"

const Toggle: React.FC<IToggleProps> = ({ label, labelLeft, labelRight, checked, disabled, onChange }) => {
    return(
        <Container>
            {label && <Label>{label}:</Label>}
            <ToggleConainer>
                <ToggleLabel>{labelLeft}</ToggleLabel>
                <ToggleSelector 
                    checked={checked}
                    checkedIcon={false} 
                    uncheckedIcon={false} 
                    onChange={onChange} 
                    disabled={disabled}
                />
                <ToggleLabel>{labelRight}</ToggleLabel>
            </ToggleConainer>
        </Container>
    )
}

export default Toggle