import styled from 'styled-components'
import { IInputProps } from './types'

export const Container = styled.div<IInputProps>`
    width: ${props => props.width || 100}%;

    @media (max-width: 768px) {
        width: ${props => props.widthMobile || 100}%;
    }
`
export const InputLabel = styled.label`
    width: 100%;
    color: ${props => props.theme.colors.white};
`

export const InputField = styled.input`
    width: 100%;
    padding: 10px;
    margin: 7px 0;
    font-size: 16px;
    border-radius: 10px;

    &:disabled {
        color: ${props => props.theme.colors.white};
    }
`