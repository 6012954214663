import Api from "../../config/Api"
import { CreateResponse, Mensagem, MensagemDTO, GetResponse } from "../../models"

export const criar = async (model: Mensagem) => {
  return await Api.post<CreateResponse>('Mensagem', model)
}

export const atualizar = async (model: Mensagem) => {
  return await Api.put<void>('Mensagem', model)
}

export const listar = async (filtro: string, page: number, rowsPerPage: number) => {
  return await Api.get<GetResponse<MensagemDTO[]>>(
    `Mensagem?Offset=${page * rowsPerPage}&Limit=${rowsPerPage}&SortDesc=true&OrderBy=descricao&Select=new(id,descricao,status)${filtro}`,
  )
}

export const buscar = async (id: string) => {
  return await Api.get<Mensagem>(`Mensagem/${id}`)
}

export const remover = async (id: string) => {
  return await Api.delete<void>(`Mensagem/${id}`)
}

export const buscarAtivos = async () => {
  return await Api.get<GetResponse<MensagemDTO[]>>(`Mensagem?Offset=0&Limit=-1&SortDesc=false&OrderBy=descricao&Active&Select=new(id,descricao,status)`)
}

export const enviarMensagens = async (eventoId: string, mensagemId: string) => {
  return await Api.put<void>(`Mensagem/EnviarTodos/${eventoId}/${mensagemId}`, undefined)
}

export const enviarMensagem = async (convidadoId: string, mensagemId: string) => {
  return await Api.put<void>(`Mensagem/Enviar/${convidadoId}/${mensagemId}`, undefined)
}

export const buscarVariaveis = async () => {
  return await Api.get<string[]>(`Mensagem/BuscarVariaveis`)
}