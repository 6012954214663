import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../../../hooks/auth"
import { useTheme } from "styled-components"
import { enumToArray } from "../../../utils/enumToArray"
import { EStatus, MensagemDTO } from "../../../models"
import Loader from "../../../components/Loader"
import Title from "../../../components/Title"
import Form from "../../../components/Form"
import Input from "../../../components/Input"
import { removerAcentos } from "../../../utils/removerAcentos"
import Select from "../../../components/Select"
import GroupButtons from "../../../components/GroupButtons"
import Button from "../../../components/Button"
import List from "../../../components/List"
import CardMensagem from "../../../components/CardMensagem"
import Pagination from "../../../components/Pagination"
import * as MensagemService from '../../../services/Mensagem/MensagemService'
import { toast } from "react-toastify"
import { errorHandle } from "../../../utils/errorHandle"
import { montaQuery } from "../../../utils/montaQuery"
import { confirmAlert } from "react-confirm-alert"
import { IListaMensagemProps } from "./types"
import { Container } from "./styles"

const ListaMensagem: React.FC<IListaMensagemProps> = () => {
    const [descricao, setDescricao] = useState<string>('')
    const [ativo, setAtivo] = useState<string>('0')

    const [loading, setLoading] = useState<boolean>(false)
    const [mensagens, setMensagens] = useState<MensagemDTO[]>([])

    const [filtros, setFiltros] = useState<string>('')
    const [page, setPage] = useState<number>(0)
    const [totalCount, setTotalCount] = useState<number>(0)
    const rowsPerPage = 50

    const theme = useTheme()
    const navigate = useNavigate()
    const { isAdmin } = useAuthContext()

    const listaStatus = [{ id: '0', descricao: 'Todos' }, ...enumToArray(EStatus)]

    useEffect(() => {
        getMensagens(filtros, page, rowsPerPage)
    }, [filtros, page, rowsPerPage])

    const getMensagens = async (filtro: string, page: number, rowsPerPage: number) => {
        setLoading(true)
        await MensagemService.listar(filtro, page, rowsPerPage)
            .then(res => {
                setMensagens(res.data.data)
                setTotalCount(res.data.totalCount)
            })
            .catch(err => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const handleSubmit = (): void => {
        const params = handlerFilter()
        setFiltros(params)
    }

    const limparFiltros = () => {
        setDescricao('')
        setAtivo('0')
        setFiltros('')
    }

    const handlerFilter = (): string => {
        let params = ''
        if (descricao && descricao !== '') {
            params += montaQuery(params)
            params += `descricao.contains("${descricao}")`
        }
        if (ativo !== '0') {
            params += `&Active=${ativo === '1' ? 'true' : 'false'}`
        }
        if (params !== '') 
            setPage(0)

        return params
    }

    const handleEditar = (mensagemId: string): void => {
        navigate(`/mensagem/${mensagemId}`)
    }

    const handleRemover = async (mensagemId: string, descricao: string): Promise<void> => {
        confirmAlert({
            title: 'Deletar mensagem',
            message: `deseja mesmo deletar a mensagem ${descricao}?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        setLoading(true)
                        await MensagemService.remover(mensagemId)
                            .then(() => {
                                toast.success(`A mensagem ${descricao} foi deletada com sucesso`)
                                getMensagens(filtros, page, rowsPerPage)
                            })
                            .catch((err) => toast.error(errorHandle(err)))
                            .finally(() => setLoading(false))
                    }
                },
                {
                    label: 'Não',
                    onClick: () => {}
                }
            ]
        })
    }

    return(
        <>
            {loading
                ? <Loader />
                : <Container>
                    <Title color={theme!.colors.warning} size={30}>
                        Lista de Mensagens
                    </Title>
                    <Form>
                        <Input 
                            width={45}
                            widthMobile={93}
                            label="Descrição"
                            placeholder="Descrição" 
                            type="text" 
                            value={descricao} 
                            onChange={(e) => setDescricao(removerAcentos(e.target.value?.toUpperCase()))} 
                        />
                        <Select 
                            width={45}
                            widthMobile={93}
                            label="Status"
                            itens={listaStatus} 
                            value={ativo} 
                            onChange={(e) => setAtivo(e.target.value)} 
                        />
                    </Form>
                    <GroupButtons>
                        <Button 
                            $backgroundColor={theme!.colors.info} 
                            type="button" 
                            onClick={handleSubmit}
                        >
                            Buscar
                        </Button>
                        <Button 
                            $backgroundColor={theme!.colors.warning} 
                            type="button" 
                            onClick={limparFiltros}
                        >
                            Limpar
                        </Button>
                    </GroupButtons>
                    <GroupButtons>
                        <Button 
                            $backgroundColor={theme!.colors.success} 
                            type="button"
                            hidden={!isAdmin}
                            onClick={() => navigate('/mensagem/new')}
                        >
                            Novo
                        </Button>
                        <Button
                            $backgroundColor={theme!.colors.warning}
                            type="button"
                            onClick={() => navigate('/')}
                        >
                            Voltar
                        </Button>
                    </GroupButtons>
                    <List>
                        {
                            mensagens.map(mensagem => (
                                <CardMensagem 
                                    key={mensagem.id} 
                                    mensagem={mensagem} 
                                    editar={handleEditar}
                                    remover={handleRemover}
                                />
                            ))
                        }
                    </List>
                    <Pagination 
                        page={page} 
                        rowsPerPage={rowsPerPage} 
                        totalCount={totalCount} 
                        handlePage={(e) => setPage(e.selected)} 
                    />
                </Container>
            }
        </>
    )
}

export default ListaMensagem