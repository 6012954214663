import { useTheme } from "styled-components"
import { Container, GroupContainer, GroupLabels, LabelCard } from "./styles"
import { ICardMensagemHistoryProps } from "./types"
import { EStatus } from "../../models"
import { format } from "date-fns"

const CardMensagemHistory: React.FC<ICardMensagemHistoryProps> = ({ historico }) => {
    const theme = useTheme()

    return(
        <Container $tagColor={historico.status === EStatus.Ativo ? theme!.colors.success : theme!.colors.warning}>
            <GroupContainer>
                <GroupLabels>
                    <LabelCard>Data Criação: {format(new Date(historico.dataCriacao!), 'dd/MM/yyyy HH:mm:ss')}</LabelCard>
                    <LabelCard>Usuário: {historico.usuario}</LabelCard>
                </GroupLabels>
                <GroupLabels>
                    <LabelCard>Nome: {historico.mensagem?.descricao}</LabelCard>
                </GroupLabels>
            </GroupContainer>
        </Container>
    )
}

export default CardMensagemHistory