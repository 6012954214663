import Content from "../Content"
import Header from "../Header"
import { Container } from "./styles"
import { ILayoutProps } from "./types"

const Layout: React.FC<ILayoutProps> = ({ children }) => {
    return(
        <Container>
            <Header />
            <Content>
                {children}
            </Content>
        </Container>
    )
}

export default Layout