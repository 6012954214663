import styled from 'styled-components'

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`

export const ModalContainer = styled.div`
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #000;
    max-width: 60%;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        max-width: 80%;
    }
`

export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ModalCloseButton = styled.button`
    background: none;
    color: ${props => props.theme.colors.white};
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
`

export const ModalContent = styled.div`
    margin-top: 10px;
`