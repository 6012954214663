import { useEffect, useState } from "react"
import { Container, Table, TBody, Td, Th, Thead, Tr } from "./styles"
import { IScoreProps } from "./types"
import Loader from "../../components/Loader"
import { useTheme } from "styled-components"
import { useNavigate, useParams } from "react-router-dom"
import * as ConvidadoHistoryService from '../../services/ConvidadoHistory/ConvidadoHistory'
import { toast } from "react-toastify"
import { errorHandle } from "../../utils/errorHandle"
import Title from "../../components/Title"
import GroupButtons from "../../components/GroupButtons"
import Button from "../../components/Button"
import { ScoreDTO } from "../../models"

const Score: React.FC<IScoreProps> = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [dados, setDados] = useState<ScoreDTO[]>([])

    const [totalQrCode, setTotalQrCode] = useState<number>(0)
    const [totalManual, setTotalManual] = useState<number>(0)
    const [totalGeral, setTotalGeral] = useState<number>(0)

    const theme = useTheme()
    const { eventoId, eventoDescricao } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        getScore()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getTotalizador()
        // eslint-disable-next-line
    }, [dados])

    const getScore = async (): Promise<void> => {
        setLoading(true)
        await ConvidadoHistoryService.buscarScore(eventoId!)
            .then((res) => {
                setDados(res.data)
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const getTotalizador = () => {
        setTotalQrCode(dados.reduce((acumulador, item) => acumulador + item.entrouQrCode, 0))
        setTotalManual(dados.reduce((acumulador, item) => acumulador + item.entrouManual, 0))
        setTotalGeral(dados.reduce((acumulador, item) => acumulador + item.total, 0))
    }

    return(
        <>
            {
                loading
                    ? <Loader />
                    : <Container>
                        <Title color={theme!.colors.warning} size={30}>Score</Title>
                        <Title color={theme!.colors.info} size={20}>{eventoDescricao!}</Title>
                        <GroupButtons>
                            <Button 
                                $backgroundColor={theme!.colors.warning}
                                type="button"
                                onClick={() => navigate('/')}
                            >
                                Voltar
                            </Button>
                        </GroupButtons>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Usuário</Th>
                                    <Th>Entrou Qr Code</Th>
                                    <Th>Entrou Manual</Th>
                                    <Th>Entrou Total</Th>
                                </Tr>
                            </Thead>
                            <TBody>
                                {
                                    dados.map(d => 
                                        <Tr key={d.usuario}>
                                            <Td>{d.usuario}</Td>
                                            <Td>{d.entrouQrCode}</Td>
                                            <Td>{d.entrouManual}</Td>
                                            <Td>{d.total}</Td>
                                        </Tr>
                                    )
                                }
                                <Tr>
                                    <Th>Total</Th>
                                    <Th>{totalQrCode}</Th>
                                    <Th>{totalManual}</Th>
                                    <Th>{totalGeral}</Th>
                                </Tr>
                            </TBody>
                        </Table>
                    </Container>
            }
        </>
    )
}

export default Score