import { useTheme } from "styled-components"
import Button from "../../components/Button"
import GroupButtons from "../../components/GroupButtons"
import Input from "../../components/Input"
import Title from "../../components/Title"
import { Container, Form, LabelAvisos, Logo } from "./styles"
import { IRegistoProps } from "./types"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../../hooks/auth"
import Loader from "../../components/Loader"

const Registo: React.FC<IRegistoProps> = () => {
    const [email, setEmail] = useState<string>('')
    const [senha, setSenha] = useState<string>('')
    const [confirmarSenha, setConfirmarSenha] = useState<string>('')

    const theme = useTheme()
    const navigate = useNavigate()
    const { register, loading } = useAuthContext()

    const handleRegister = async (e: any) => {
        e.preventDefault()
        await register(email, senha, confirmarSenha)
        setEmail('')
        setSenha('')
        setConfirmarSenha('')
    }

    return(
        <>
            {
                loading
                    ? <Loader />
                    : <Container>
                        <Logo>CheckMyList</Logo>
                        <LabelAvisos>
                            A senha deve conter letras maiúsculas, minúsculas, números e caracteres especiais. 
                            <br /> 
                            Obs: Após o cadastro é necessário aguardar a aprovação dos administradores.
                        </LabelAvisos>
                        <Form onSubmit={(e: any) => handleRegister(e)}>
                            <Title color={theme!.colors.warning} size={30}>Registre-se</Title>
                            <Input label="Email *" placeholder="Email *" type="text" required value={email} onChange={(e) => setEmail(e.target.value)} />
                            <Input label="Senha *" placeholder="Senha *" type="password" required value={senha} onChange={(e) => setSenha(e.target.value)} />
                            <Input label="Confirmar Senha *" placeholder="Confirmar Senha *" type="password" required value={confirmarSenha} onChange={(e) => setConfirmarSenha(e.target.value)} />
                            <GroupButtons>
                                <Button $backgroundColor={theme!.colors.success} type="submit">Registrar</Button>
                                <Button $backgroundColor={theme!.colors.warning} type="button" onClick={() => navigate('/')}>Voltar</Button>
                            </GroupButtons>
                        </Form>
                    </Container>
            }
        </>
    )
}

export default Registo