import { useTheme } from "styled-components"
import Title from "../../components/Title"
import { Container } from "./styles"
import { IConvidadoHistoricoProps } from "./types"
import { useNavigate, useParams } from "react-router-dom"
import List from "../../components/List"
import GroupButtons from "../../components/GroupButtons"
import Button from "../../components/Button"
import { useState, useEffect } from "react"
import { ConvidadoHistory } from "../../models"
import Loader from "../../components/Loader"
import * as ConvidadoHistoryService from '../../services/ConvidadoHistory/ConvidadoHistory'
import { toast } from "react-toastify"
import { errorHandle } from "../../utils/errorHandle"
import CardConvidadoHistory from "../../components/CardConvidadoHistory"
import QrCodeLegenda from "../../components/QrCodeLegenda"

const ConvidadoHistorico: React.FC<IConvidadoHistoricoProps> = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [historicos, setHistoricos] = useState<ConvidadoHistory[]>([])

    const theme = useTheme()
    const navigate = useNavigate()
    const { eventoId, eventoDescricao, convidadoId, convidadoNome} = useParams()

    useEffect(() => {
        getHistoricos()
        // eslint-disable-next-line
    }, [])

    const getHistoricos = async (): Promise<void> => {
        setLoading(true)
        await ConvidadoHistoryService.getByConvidadoId(convidadoId!)
            .then((res) => {
                setHistoricos(res.data.data)
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    return(
        <>
            {
                loading
                    ? <Loader />
                    : <Container>
                        <Title color={theme!.colors.warning} size={30}>Histórico de Convidados</Title>
                        <Title color={theme!.colors.info} size={20}>{convidadoNome!}</Title>
                        <GroupButtons>
                            <Button 
                                $backgroundColor={theme!.colors.warning}
                                type='button'
                                onClick={() => navigate(`/convidado/${eventoId}/${eventoDescricao}`)}
                            >
                                Voltar
                            </Button>
                        </GroupButtons>
                        <QrCodeLegenda />
                        <List>
                            {
                                historicos.map(historico => (
                                    <CardConvidadoHistory 
                                        key={historico.id} 
                                        historico={historico}
                                    />
                                ))
                            }
                        </List>
                    </Container>
            }
        </>
    )
}

export default ConvidadoHistorico