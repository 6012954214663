import { Container, InputField, InputLabel } from "./styles"
import { IInputProps } from "./types"

const Input: React.FC<IInputProps> = ({ label, widthMobile, ...props }) => {
    return(
        <Container widthMobile={widthMobile} { ...props }>
            {label && <InputLabel>{label}:</InputLabel>}
            <InputField {...props} />
        </Container>
    )
}

export default Input