import { Container, TextAreaField, TextAreaLabel } from "./styles"
import { ITextAreaProps } from "./types"

const TextArea: React.FC<ITextAreaProps> = ({ label, width, widthMobile, ...props }) => {
    return(
        <Container width={width} widthMobile={widthMobile}>
            {label && <TextAreaLabel>{label}:</TextAreaLabel>}
            <TextAreaField {...props} />
        </Container>
    )
}

export default TextArea