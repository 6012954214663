import { Item } from "../models";

export function enumToArray(enumObject: any): Item[] {
    const keys = Object.keys(enumObject);
    const values = keys.filter(k => typeof enumObject[k] === 'number');

    return values.map(v => ({
        id: enumObject[v],
        descricao: v
    }))
}