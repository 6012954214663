import { useState } from "react"
import { useToggleTheme } from "../../hooks/theme"
import Toggle from "../Toggle"
import { Container, Profile, UserLogout, Logo } from "./styles"
import { IHeaderProps } from "./types"
import { useAuthContext } from "../../hooks/auth"

const Header: React.FC<IHeaderProps> = () => {
    const { toggleTheme, theme } = useToggleTheme()
    const [darTheme, setDarkTheme] = useState<boolean>(() => theme.title === 'dark')

    const { logout, user } = useAuthContext()

    const handleChangeTheme = () => {
        setDarkTheme(!darTheme)
        toggleTheme()
    }

    return(
        <Container>
            <Toggle
                labelLeft="Light"
                labelRight="Dark"
                checked={darTheme}
                onChange={handleChangeTheme}
            />
            <Logo>CheckMyList</Logo>
            <Profile>
                <span>{user}</span>
                <UserLogout onClick={logout}>Sair</UserLogout>
            </Profile>
        </Container>
    )
}

export default Header