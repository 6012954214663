import { useTheme } from "styled-components"
import Form from "../../../components/Form"
import Title from "../../../components/Title"
import { Container } from "./styles"
import { ICadastroEventoProps } from "./types"
import Input from "../../../components/Input"
import Toggle from "../../../components/Toggle"
import Button from "../../../components/Button"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { EStatus, Evento } from "../../../models"
import * as EventoService from '../../../services/Evento/EventoService'
import { toast } from "react-toastify"
import { errorHandle } from "../../../utils/errorHandle"
import Loader from "../../../components/Loader"
import { guidEmpty } from "../../../utils/guidEmpty"
import { dateForInput, inputToDate } from "../../../utils/dateFormats"
import GroupButtons from "../../../components/GroupButtons"
import { removerAcentos } from "../../../utils/removerAcentos"

const CadastroEvento: React.FC<ICadastroEventoProps> = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [evento, setEvento] = useState<Evento>({
        id: guidEmpty(),
        descricao: '',
        data: new Date(),
        status: EStatus.Ativo
    } as Evento)

    const theme = useTheme()
    const { id } = useParams()
    const navigate = useNavigate()
    const isNew = id === 'new'

    useEffect(() => {
        if(!isNew)
            getEvento()
    // eslint-disable-next-line
    }, [isNew])

    const getEvento = async (): Promise<void> => {
        setLoading(true)
        await EventoService.buscar(id ?? '')
            .then((res) => {
                setEvento(res.data)
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const handleDescricao = (descricao: string): void => {
        setEvento((prevState) => ({
            ...prevState,
            descricao: removerAcentos(descricao?.toUpperCase())
        }))
    }

    const handleData = (data: Date): void => {
        setEvento((prevState) => ({
            ...prevState,
            data: data
        }))
    }

    const handleStatus = (status: EStatus): void => {
        setEvento((prevState) => ({
            ...prevState,
            status: status
        }))
    }

    const handleSubmit = async (): Promise<void> => {
        if(isNew)
            await criarEvento()
        else
            await atualizarEvento()
    }

    const criarEvento = async (): Promise<void> => {
        setLoading(true)
        await EventoService.criar(evento)
            .then(() => {
                toast.success('Evento criado com sucesso')
                navigate('/')
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const atualizarEvento = async (): Promise<void> => {
        setLoading(true)
        await EventoService.atualizar(evento)
            .then(() => {
                toast.success('Evento atualizado com sucesso')
                navigate('/')
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    return(
        <>
            {
                loading
                ? <Loader />
                : <Container>
                    <Title 
                        color={theme!.colors.warning}
                        size={30}
                    >
                        Cadastro de Evento
                    </Title>
                    <Form>
                        <Input 
                            width={45}
                            label="Descrição *"
                            placeholder="Descrição *" 
                            type="text" 
                            required 
                            maxLength={150}
                            value={evento.descricao}
                            onChange={(e) => handleDescricao(e.target.value)}
                        />
                        <Input 
                            width={45}
                            label="Data *"
                            placeholder="Data *" 
                            type="date" 
                            required 
                            value={dateForInput(new Date(evento.data))}
                            onChange={(e) => handleData(inputToDate(e.target.value) ?? new Date())}
                        />
                        <Toggle 
                            label="Status"
                            labelLeft="Inativo" 
                            labelRight="Ativo" 
                            checked={evento.status === EStatus.Ativo} 
                            onChange={() => handleStatus(evento.status === EStatus.Ativo ? EStatus.Inativo : EStatus.Ativo)} 
                        />
                    </Form>
                    <GroupButtons>
                        <Button 
                            $backgroundColor={theme!.colors.info} 
                            type="button" 
                            onClick={handleSubmit}
                        >
                            Salvar
                        </Button>
                        <Button 
                            $backgroundColor={theme!.colors.warning} 
                            type="button" 
                            onClick={() => navigate('/')}
                        >
                            Voltar
                        </Button>
                    </GroupButtons>
                </Container>
            }
        </>
    )
}

export default CadastroEvento