import { Container } from "./styles"
import { IGroupButtonsProps } from "./types"

const GroupButtons: React.FC<IGroupButtonsProps> = ({ children }) => {
    return(
        <Container>
            {children}
        </Container>
    )
}

export default GroupButtons