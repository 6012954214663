import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

export const GroupToggle = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
`