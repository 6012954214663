import { useTheme } from "styled-components"
import { EStatus } from "../../models"
import { Container, GroupContainer, GroupLabels, LabelCard } from "./styles"
import { ICardEventoHistoryProps } from "./types"
import { format } from "date-fns"

const CardEventoHistory: React.FC<ICardEventoHistoryProps> = ({ historico }) => {
    const theme = useTheme()

    return(
        <Container $tagColor={historico.status === EStatus.Ativo ? theme!.colors.success : theme!.colors.warning}>
            <GroupContainer>
                <GroupLabels>
                    <LabelCard>Data Criação: {format(new Date(historico.dataCriacao!), 'dd/MM/yyyy - HH:mm:ss')}</LabelCard>
                    <LabelCard>Usuário: {historico.usuario}</LabelCard>
                </GroupLabels>
                <GroupLabels>
                    <LabelCard>Descricao: {historico.descricao}</LabelCard>
                    <LabelCard>Data: {format(new Date(historico.data), 'dd/MM/yyyy')}</LabelCard>
                </GroupLabels>
            </GroupContainer>
        </Container>
    )
}

export default CardEventoHistory