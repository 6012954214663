import { useTheme } from "styled-components"
import { ActionsButtons, Container, GroupContainer, GroupLabels, IconButton, LabelCard } from "./styles"
import { ICardConvidadoProps } from "./types"
import { EStatus } from "../../models"
import { MdDelete, MdEdit, MdCheck, MdClose, MdAccessTime, MdQrCode, MdMail, MdMarkEmailRead } from "react-icons/md"
import { celularInsertMask } from "../../utils/maskCelular"
import { useAuthContext } from "../../hooks/auth"
import { useToggleTheme } from "../../hooks/theme"

const CardConvidado: React.FC<ICardConvidadoProps> = ({ convidado, editar, enviarQrCode, remover, historico, enviarMensagem, historicoMensagem }) => {
    const theme = useTheme()
    const { colorEnvio } = useToggleTheme()
    const { isAdmin } = useAuthContext()

    return(
        <Container $tagColor={convidado.status === EStatus.Ativo ? theme!.colors.success : theme!.colors.warning}>
            <GroupContainer>
                <GroupLabels>
                    <LabelCard>Nome: {convidado.nome}</LabelCard>
                    <LabelCard>celular: {celularInsertMask(convidado.celular)}</LabelCard>
                    <LabelCard>Idade: {convidado.idade}</LabelCard>
                </GroupLabels>
                <GroupLabels>
                    <LabelCard>Mesa: {convidado.mesa}</LabelCard>
                    <LabelCard>
                        Confirmado?: {
                            convidado.confirmado 
                                ? <MdCheck color={theme!.colors.info} size={30} style={{backgroundColor: theme!.colors.tertiary}} /> 
                                : <MdClose color={theme!.colors.warning} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                        }
                    </LabelCard>
                    <LabelCard>
                        Entrou?: {
                            convidado.entrou 
                                ? <MdCheck color={theme!.colors.info} size={30} style={{backgroundColor: theme!.colors.tertiary}} /> 
                                : <MdClose color={theme!.colors.warning} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                        }
                    </LabelCard>
                    <LabelCard>
                        Entrou Qr Code?: {
                            convidado.entrouQrCode 
                                ? <MdCheck color={theme!.colors.info} size={30} style={{backgroundColor: theme!.colors.tertiary}} /> 
                                : <MdClose color={theme!.colors.warning} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                        }
                    </LabelCard>
                </GroupLabels>
            </GroupContainer>
            <ActionsButtons>
                <IconButton onClick={() => editar(convidado.id)}>
                    <MdEdit color={theme!.colors.info} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                </IconButton>
                <IconButton hidden={!isAdmin || !convidado.confirmado || convidado.entrou || convidado.entrouQrCode || convidado.status !== EStatus.Ativo} onClick={() => enviarQrCode(convidado.id, convidado.nome)}>
                    <MdQrCode color={colorEnvio(convidado.statusEnvio)} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                </IconButton>
                <IconButton hidden={!isAdmin} onClick={() => remover(convidado.id, convidado.nome)}>
                    <MdDelete color={theme!.colors.warning} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                </IconButton>
                <IconButton hidden={!isAdmin} onClick={() => historico(convidado.id, convidado.nome)}>
                    <MdAccessTime color={theme!.colors.info} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                </IconButton>
                <IconButton hidden={!isAdmin} onClick={() => enviarMensagem(convidado.id)}>
                    <MdMail color={theme!.colors.success} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                </IconButton>
                <IconButton hidden={!isAdmin} onClick={() => historicoMensagem(convidado.id, convidado.nome)}>
                    <MdMarkEmailRead color={theme!.colors.info} size={30} style={{backgroundColor: theme!.colors.tertiary}} />
                </IconButton>
            </ActionsButtons>
        </Container>
    )
}

export default CardConvidado