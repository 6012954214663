import { createContext, useState, useContext } from 'react'
import { dark, light } from '../styles/theme'
import { EStatusEnvio, IChildrenProps } from '../models'
import { ConstStorage } from '../utils/constStorage'

interface ITheme {
    title: string,
    colors: {
        primary: string,
        secondary: string,
        tertiary: string,

        white: string,
        black: string,
        gray: string,

        success: string,
        info: string,
        warning: string
    }
}

interface IThemeContext {
    toggleTheme: () => void,
    theme: ITheme,
    colorEnvio: (statusEnvio: EStatusEnvio) => string
}

const ThemeContext = createContext<IThemeContext>({} as IThemeContext)

const ThemeProvider: React.FC<IChildrenProps> = ({ children }) => {
    const [theme, setTheme] = useState<ITheme>(() => {
        const themeSaved = localStorage.getItem(ConstStorage.checkMyListTheme)

        if(themeSaved)
            return JSON.parse(themeSaved)
        else
            return dark
    })

    const toggleTheme = (): void => {
        if(theme.title === 'dark'){
            setTheme(light)
            localStorage.setItem(ConstStorage.checkMyListTheme, JSON.stringify(light))
        } else {
            setTheme(dark)
            localStorage.setItem(ConstStorage.checkMyListTheme, JSON.stringify(dark))
        }
    }

    const colorEnvio = (statusEnvio: EStatusEnvio): string => {
        switch(statusEnvio) {
            case EStatusEnvio.NaoEnviado:
                return theme!.colors.warning
            case EStatusEnvio.Enviado:
                return theme!.colors.info
            case EStatusEnvio.Lido:
                return theme!.colors.success
            default:
                return theme!.colors.white
        }
    }

    return(
        <ThemeContext.Provider value={{toggleTheme, theme, colorEnvio}}>
            {children}
        </ThemeContext.Provider>
    )
}

const useToggleTheme = () => {
    const context = useContext(ThemeContext)
    return context
}

export { ThemeProvider, useToggleTheme }