import { FileData } from "../models";

export function fileToBase64(file: File): Promise<FileData> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
        const base64String = (reader.result as string).split(',')[1]; // Remove o prefixo data:...
            const fileData: FileData = {
                base64: base64String,
                fileName: file.name,
                contentType: file.type,
            };
            resolve(fileData);
        };

        reader.onerror = () => {
            reject(new Error("Erro ao ler o arquivo"));
        };

        reader.readAsDataURL(file); // Lê o arquivo e dispara onloadend
    });
}