import { useEffect, useState } from "react"
import { Container, GroupToggle } from "./styles"
import { ICadastroMensagemProps } from "./types"
import Loader from "../../../components/Loader"
import { useTheme } from "styled-components"
import { useNavigate, useParams } from "react-router-dom"
import { EStatus, Mensagem } from "../../../models"
import { guidEmpty } from "../../../utils/guidEmpty"
import Title from "../../../components/Title"
import Form from "../../../components/Form"
import Input from "../../../components/Input"
import Toggle from "../../../components/Toggle"
import GroupButtons from "../../../components/GroupButtons"
import Button from "../../../components/Button"
import * as MensagemService from '../../../services/Mensagem/MensagemService'
import { toast } from "react-toastify"
import { errorHandle } from "../../../utils/errorHandle"
import TextArea from "../../../components/TextArea"
import UploadButton from "../../../components/UploadButton"
import { fileToBase64 } from "../../../utils/fileToBase64"

const CadastroMensagem: React.FC<ICadastroMensagemProps> = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const { id } = useParams()

    const isNew = id === 'new'

    const [loading, setLoading] = useState<boolean>(false)
    const [variaveis, setVariaveis] = useState<string[]>([])
    const [mensagem, setMensagem] = useState<Mensagem>({
        id: guidEmpty(),
        descricao: '',
        text: '',
        file: '',
        fileName: '',
        contentType: '',
        status: EStatus.Ativo
    })

    useEffect(() => {
        getVariaveis()
    }, [])

    useEffect(() => {
        if(!isNew)
            getMensagem()
    // eslint-disable-next-line
    }, [isNew])

    const getMensagem = async (): Promise<void> => {
        setLoading(true)
        await MensagemService.buscar(id ?? '')
            .then((res) => {
                setMensagem(res.data)
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const getVariaveis = async () => {
        await MensagemService.buscarVariaveis()
            .then(res => {
                setVariaveis(res.data)
            })
            .catch((err) => toast.error(errorHandle(err)))
    }

    const handleDescricao = (descricao: string): void => {
        setMensagem((prevState) => ({
            ...prevState,
            descricao: descricao
        }))
    }

    const handleFile = (file: string): void => {
        setMensagem((prevState) => ({
            ...prevState,
            file: file
        }))
    }

    const handleFileName = (fileName: string): void => {
        setMensagem((prevState) => ({
            ...prevState,
            fileName: fileName
        }))
    }

    const handleContentType = (contentType: string): void => {
        setMensagem((prevState) => ({
            ...prevState,
            contentType: contentType
        }))
    }

    const handleText = (text: string): void => {
        setMensagem((prevState) => ({
            ...prevState,
            text: text
        }))
    }

    const handleStatus = (status: EStatus): void => {
        setMensagem((prevState) => ({
            ...prevState,
            status: status
        }))
    }

    const handleSubmit = async (): Promise<void> => {
        if(isNew)
            await criarMensagem()
        else
            await atualizarMensagem()
    }

    const criarMensagem = async (): Promise<void> => {
        setLoading(true)
        await MensagemService.criar(mensagem)
            .then(() => {
                toast.success('Mensagem criada com sucesso')
                navigate(`/Mensagem`)
            })
            .catch((err) => toast.error(errorHandle(err)))
            .finally(() => setLoading(false))
    }

    const atualizarMensagem = async (): Promise<void> => {
        setLoading(true)
        await MensagemService.atualizar(mensagem)
        .then(() => {
            toast.success('Mensagem atualizada com sucesso')
            navigate(`/Mensagem`)
        })
        .catch((err) => toast.error(errorHandle(err)))
        .finally(() => setLoading(false))
    }

    const handleImportar = async (arquivo: File): Promise<void> => {
        await fileToBase64(arquivo)
            .then((fileData) => {
                handleFile(fileData.base64)
                handleFileName(fileData.fileName)
                handleContentType(fileData.contentType)
            })
            .catch((err) => toast.error(errorHandle(err)))
    }

    const limparArquivo = () => {
        setMensagem((prevState) => ({
            ...prevState,
            file: '',
            fileName: '',
            contentType: ''
        }))
    }
    
    return(
        <>
            {
                loading
                    ? <Loader />
                    : <Container>
                        <Title 
                            color={theme!.colors.warning}
                            size={30}
                        >
                            Cadastro de Mensagem
                        </Title>
                        <Form>
                            <Input 
                                width={93}
                                widthMobile={93}
                                label="Descricao *" 
                                placeholder="Descrição *" 
                                type="text" 
                                required 
                                value={mensagem.descricao}
                                onChange={(e) => handleDescricao(e.target.value)}
                            />
                            <GroupButtons>
                                { 
                                    variaveis.map(v => <Button 
                                        key={v} 
                                        $backgroundColor={theme!.colors.success}
                                        type="button"
                                        onClick={() => handleText(mensagem.text + v)}
                                    >
                                        {v}
                                    </Button>) 
                                }
                            </GroupButtons>
                            <TextArea
                                width={93}
                                widthMobile={93}
                                label="Texto"
                                placeholder="Texto"
                                value={mensagem.text}
                                onChange={(e) => handleText(e.target.value)}
                            />
                            <Input 
                                width={93}
                                widthMobile={93}
                                label="Arquivo" 
                                placeholder="Arquivo" 
                                type="text" 
                                value={mensagem.fileName}
                                readOnly
                                disabled
                            />
                        </Form>
                        <GroupButtons>
                            <UploadButton
                                $backgroundColor={theme!.colors.success}
                                onFileUpload={handleImportar}
                            >
                                Importar Arquivo
                            </UploadButton>
                            <Button 
                                $backgroundColor={theme!.colors.warning} 
                                type="button"
                                onClick={limparArquivo}
                            >
                                Remover Arquivo
                            </Button>
                        </GroupButtons>
                        <GroupToggle>
                            <Toggle 
                                label="Status" 
                                labelLeft="Inativo" 
                                labelRight="Ativo" 
                                checked={mensagem.status === EStatus.Ativo} 
                                onChange={() => handleStatus(mensagem.status === EStatus.Ativo ? EStatus.Inativo : EStatus.Ativo)} 
                            />
                        </GroupToggle>
                        <GroupButtons>
                            <Button 
                                $backgroundColor={theme!.colors.info} 
                                type="button"
                                onClick={handleSubmit}
                            >
                                Salvar
                            </Button>
                            <Button 
                                $backgroundColor={theme!.colors.warning} 
                                type="button" 
                                onClick={() => navigate(`/Mensagem`)}
                            >
                                Voltar
                            </Button>
                        </GroupButtons>
                    </Container>
            }
        </>
    )
}

export default CadastroMensagem