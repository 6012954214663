import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    border-bottom: 1px solid ${props => props.theme.colors.secondary};
    background-color: ${props => props.theme.colors.secondary};
    height: 70px;
`

export const Profile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    color: ${props => props.theme.colors.white};
`

export const UserLogout = styled.button`
    font-size: 16px;
    color: ${props => props.theme.colors.info};
    border: none;
    background: none;
    margin: 7px 0;
    display: flex;
    align-items: center;
    transition: opacity 0.3s;

    &:hover {
        opacity: 0.7;
    }
`

export const Logo = styled.h1`
    color: ${props => props.theme.colors.white};

    @media (max-width: 768px) {
        font-size: 20px;
    }
`
