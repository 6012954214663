import { Container } from "./styles"
import { IButtonProps } from "./types"

const Button: React.FC<IButtonProps> = ({ children, $backgroundColor, ...props }) => {
    return(
        <Container $backgroundColor={$backgroundColor} {...props}>
            {children}
        </Container>
    )
}

export default Button