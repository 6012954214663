import Api from '../../config/Api'
import { UserResponseDTO } from '../../models'

export const buscarUsuarios = async () => {
    return await Api.get<UserResponseDTO[]>('User/buscar-usuarios')
}

export const bloquearDesbloquearUsuario = async (id: string, bloqueado: boolean) => {
    return await Api.put<string>(`User/bloquear-desbloquear-usuario/${id}/${bloqueado}`, null)
}

export const deletarUsuario = async (id: string) => {
    return await Api.delete<string>(`User/deletar-usuario/${id}`)
}

export const alterarPermissaoUsuario = async (id: string, isAdmin: boolean) => {
    return await Api.put<string>(`User/alterar-permissao-usuario/${id}/${isAdmin}`, null)
}