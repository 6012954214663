import { Container } from "./styles"
import { IPaginationProps } from "./types"

const Pagination: React.FC<IPaginationProps> = ({ page, rowsPerPage, totalCount, handlePage }) => {
    return (
        <Container
            previousLabel={'← Anterior'}
            nextLabel={'Próximo →'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(totalCount / rowsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePage}
            forcePage={page}
            activeClassName="active"
        />
    );
}

export default Pagination