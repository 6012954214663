export const light = {
    title: 'light',
    colors: {
        primary: '#DCDCDC',
        secondary: '#FFFFFF',
        tertiary: '#F5F5F5',

        white: '#000000',
        black: '#FFFFFF',
        gray: '#BFBFBF',

        success: '#03BB85',
        info: '#4169E1',
        warning: '#FF6961'
    }
}