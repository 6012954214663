import styled from 'styled-components'
import { ISelectStyleProps } from './types'

export const Container = styled.div<ISelectStyleProps>`
    width: ${props => props.width || 100}%;

    @media (max-width: 768px) {
        width: ${props => props.widthMobile || 100}%;
    }
`

export const SelectLabel = styled.label`
    width: 100%;
    color: ${props => props.theme.colors.white};
`

export const SelectField = styled.select`
    width: 100%;
    padding: 10px;
    margin: 7px 0;
    font-size: 16px;
    border-radius: 10px;
`