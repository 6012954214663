import { AxiosRequestConfig, AxiosResponse } from "axios"
import Api from "../../config/Api"
import { Convidado, CreateResponse, GetResponse, TotalizadorConvidadosResponse } from "../../models"

export const criar = async (model: Convidado) => {
  return await Api.post<CreateResponse>('Convidado', model)
}

export const atualizar = async (model: Convidado) => {
  return await Api.put<void>('Convidado', model)
}

export const listar = async (filtro: string, page: number, rowsPerPage: number) => {
  return await Api.get<GetResponse<Convidado[]>>(
    `Convidado?Offset=${page * rowsPerPage}&Limit=${rowsPerPage}&SortDesc=false&OrderBy=nome${filtro}`,
  )
}

export const buscar = async (id: string) => {
  return await Api.get<Convidado>(`Convidado/${id}?include=Evento`)
}

export const remover = async (id: string) => {
  return await Api.delete<void>(`Convidado/${id}`)
}

export const downloadTemplate = async (eventoId: string) => {
  const headers = {'Content-Type': 'blob'};
  const config: AxiosRequestConfig = { responseType: 'arraybuffer', headers };
  return await Api.get<Blob>(`Convidado/download-convidados/${eventoId}`, config)
}

export const uploadListaConvidados = async (eventoId: string, arquivo: FormData) => {
  return await Api.post<string>(`Convidado/upload-lista-convidados/${eventoId}`, arquivo)
}

export const uploadFormGoogle = async (eventoId: string, arquivo: FormData) => {
  return await Api.post<string>(`Convidado/upload-form-google/${eventoId}`, arquivo)
}

export const enviarQrCode = async (id: string) => {
  return await Api.get<void>(`Convidado/enviar-qr-code/${id}`) 
}

export const enviarQrCodeLote = async (eventoId: string) => {
  return await Api.get<void>(`Convidado/enviar-qr-code-lote/${eventoId}`)
}

export const buscarTotalizador = async (eventoId: string): Promise<AxiosResponse<TotalizadorConvidadosResponse, any>> => {
  return await Api.get<TotalizadorConvidadosResponse>(`Convidado/buscar-totalizador/${eventoId}`)
}